import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

const AdBanner = ({ placement }) => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const fetchAds = async () => {
      const { data, error } = await supabase
        .from('ads')
        .select('*')
        .eq('placement', placement);

      if (error) {
        console.error('Error fetching ads:', error);
      } else {
        setAds(data);
      }
    };

    fetchAds();
  }, [placement]);

  useEffect(() => {
    if (ads.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 25000); // Rotera var 5:e sekund, justera som du vill

      return () => clearInterval(intervalId); // Rensa intervallet vid avmontering
    }
  }, [ads]);

  if (ads.length === 0) {
    return null; // Returnera inget om inga annonser hittas
  }

  const currentAd = ads[currentAdIndex];

  return (
    <div className={`ad-banner ${placement}`}>
      <a href={currentAd.linkUrl} target="_blank" rel="noopener noreferrer">
        <img src={currentAd.imageUrl} alt={currentAd.altText} />
      </a>
    </div>
  );
};

export default AdBanner;


