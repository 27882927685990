import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import Comment from './Comment';
import Loader from './Loader';
import Modal from './Modal';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import '../styles/Post.css';
import '../styles/Modal.css';
import AdBanner from './AdBanner'; // Import the AdBanner component

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [similarPosts, setSimilarPosts] = useState([]);
  const [loadingSimilar, setLoadingSimilar] = useState(true);
  const [likes, setLikes] = useState(0);
  const [hasLiked, setHasLiked] = useState(false);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const { data: post, error } = await supabase
        .from('posts')
        .select('*')
        .eq('slug', slug)
        .single();

      if (error) {
        console.log('Error fetching post:', error.message);
      } else {
        setPost(post);
        setLikes(post.likes || 0);
      }
      setLoading(false);
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    const fetchSimilarPosts = async () => {
      if (post && post.categories && post.categories.length > 0) {
        setLoadingSimilar(true);
        const { data: similarPosts, error } = await supabase
          .from('posts')
          .select('*')
          .contains('categories', post.categories)
          .neq('id', post.id)
          .limit(4);

        if (error) {
          console.log('Error fetching similar posts:', error.message);
        } else {
          setSimilarPosts(similarPosts);
        }
        setLoadingSimilar(false);
      }
    };

    fetchSimilarPosts();
  }, [post]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();
  }, [post]);

  const handleLike = async () => {
    try {
      // Hämta användarens IP-adress
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const userIP = ipData.ip;

      // Kontrollera om användaren redan har gillat inlägget
      const likeCheckResponse = await fetch(`https://iwlqqtafktfqeyapqxgz.supabase.co/rest/v1/likes?select=*&post_id=eq.${post.id}&ip_address=eq.${userIP}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'apikey': process.env.REACT_APP_SUPABASE_KEY,
          'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
        },
      });

      if (!likeCheckResponse.ok) {
        throw new Error('Failed to fetch like status');
      }

      const likeCheckData = await likeCheckResponse.json();

      if (likeCheckData.length > 0) {
        // Om användaren redan har gillat inlägget
        setShowModal(true);
        return;
      }

      // Om användaren inte har gillat inlägget, lägg till en like
      setHasLiked(true);
      setLikes(likes + 1);

      // Skapa en ny like-post i likes-tabellen
      const { data: insertData, error: insertError } = await supabase
        .from('likes')
        .insert([{ post_id: post.id, ip_address: userIP }]);

      if (insertError) {
        throw new Error('Failed to insert like');
      }

      // Uppdatera likes-räknaren i posts-tabellen
      const { error: updateError } = await supabase
        .from('posts')
        .update({ likes: likes + 1 })
        .eq('id', post.id);

      if (updateError) {
        throw new Error('Failed to update post likes');
      }
    } catch (error) {
      console.error('Error handling like:', error.message);
      alert('Det gick inte att registrera din like. Vänligen försök igen senare.');
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return <Loader />;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const shareUrl = `https://codecraftsman.se/post/${post?.slug}`;
  const shareTitle = post.title || 'Check out this post!';
  const shareImage = post.images && post.images.length > 0 ? post.images[0] : '/default-image.jpg';

  return (
    <div className="container post">
      {post && (
        <>
          <Helmet>
            <title>{post.title} | CodeCraftsMan</title>
            <meta name="description" content={post.content || 'Read this post to find out more!'} />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.content || 'Read this post to find out more!'} />
            <meta property="og:url" content={shareUrl} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={shareImage} />
            <meta property="og:site_name" content="CodeCraftsMan" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.title} />
            <meta name="twitter:description" content={post.content || 'Read this post to find out more!'} />
            <meta name="twitter:image" content={shareImage} />

            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "headline": post.title,
                "image": post.images && post.images.length > 0 ? post.images[0] : '/default-image.jpg',
                "author": {
                  "@type": "Person",
                  "name": post.author || 'Author Name'
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "CodeCraftsMan",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "/logo.png"
                  }
                },
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": `https://codecraftsman.se/post/${post.slug}`
                },
                "datePublished": post.created_at,
                "dateModified": post.updated_at
              })}
            </script>

            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://codecraftsman.se"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Posts",
                    "item": "https://codecraftsman.se/posts"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": post.title,
                    "item": `https://codecraftsman.se/post/${post.slug}`
                  }
                ]
              })}
            </script>
          </Helmet>

          <h1>{post.title}</h1>

          {/* Top Banner under the post title */}
          <div className="ad-banner-wrapper post-top-banner">
            <AdBanner placement="post-top" />
          </div>

          <div className="content">
            {post.images && post.images.length > 0 && (
              <div className="image-gallery">
                {post.images.map((image, index) => (
                  <div
                    key={index}
                    className={`image-text-item ${index % 2 === 0 ? 'left' : 'right'}`}
                  >
                    <div className="image-container">
                      <img
                        src={image}
                        alt={`Post image ${index}`}
                        className="post-image"
                        loading="lazy"
                      />
                      <div className="post-date">{formatDate(post.created_at)}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: post.content }} />

            {/* Middle Banner between content */}
            <div className="ad-banner-wrapper middle-banner">
              <AdBanner placement="middle" />
            </div>

            <div className="like-section">
              <button
                className={`like-button ${hasLiked ? 'liked' : ''}`}
                onClick={handleLike}
                disabled={hasLiked}
              >
                {hasLiked ? 'Liked' : 'Like'} ({likes})
              </button>
            </div>
          </div>
          <div className="share-section">
           
            <div className="share-buttons">
              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <LinkedinShareButton url={shareUrl} title={shareTitle}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton url={shareUrl} subject={shareTitle}>
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
          {/* Similar Posts */}
        {/* Similar Posts */}
        <div className="similar-posts">
  <h3>Similar Posts</h3>
  {loadingSimilar ? (
    <Loader />
  ) : similarPosts.length > 0 ? (
    <div className="similar-posts-grid">
      {similarPosts.map((similarPost) => (
        <Link to={`/post/${similarPost.slug}`} key={similarPost.id} className="similar-post-item">
          {similarPost.images && similarPost.images.length > 0 && (
            <img
              src={similarPost.images[0]}
              alt={similarPost.title}
              className="similar-post-thumbnail"
              loading="lazy"
            />
          )}
          <div className="similar-post-title">{similarPost.title}</div>
        </Link>
      ))}
    </div>
  ) : (
    <p>No similar posts found.</p>
  )}
</div>


          {/* Bottom Banner */}
          <div className="ad-banner-wrapper post-bottom-banner">
            <AdBanner placement="post-bottom" />
          </div>

          <Comment postId={post.id} />

         
        </>
      )}

      <Modal show={showModal} onClose={closeModal}>
        <p>You have already liked this post.</p>
      </Modal>
    </div>
  );
};

export default Post;










       



