import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/CreateAd.css';

const CreateAd = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [altText, setAltText] = useState('');
  const [placement, setPlacement] = useState('top'); // Default placement
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [ads, setAds] = useState([]);

  // Fetch existing ads
  useEffect(() => {
    const fetchAds = async () => {
      const { data, error } = await supabase.from('ads').select('*').order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching ads:', error);
      } else {
        setAds(data);
      }
    };

    fetchAds();
  }, []);

  const handleCreateAd = async () => {
    if (!imageUrl || !linkUrl || !altText || !placement) {
      setError('All fields are required');
      return;
    }

    try {
      const { data, error } = await supabase.from('ads').insert([
        { imageUrl, linkUrl, altText, placement }
      ]);

      if (error) throw error;

      // Clear form fields
      setImageUrl('');
      setLinkUrl('');
      setAltText('');
      setPlacement('top');
      setError(null);
      setSuccess('Ad created successfully!');

      // Update the ads list
      setAds([data[0], ...ads]);
    } catch (error) {
      console.error('Error creating ad:', error.message);
      setError(`Error creating ad: ${error.message}`);
    }
  };

  const handleDeleteAd = async (id) => {
    try {
      const { error } = await supabase.from('ads').delete().match({ id });
      if (error) throw error;

      // Remove deleted ad from state
      setAds(ads.filter(ad => ad.id !== id));
    } catch (error) {
      console.error('Error deleting ad:', error.message);
      setError(`Error deleting ad: ${error.message}`);
    }
  };

  const handleImageLoad = (ad, event) => {
    const img = event.target;
    ad.imageWidth = img.naturalWidth;
    ad.imageHeight = img.naturalHeight;
    setAds([...ads]); // Update state to trigger re-render
  };

  return (
    <div className="create-ad-container">
      <h2>Create New Ad</h2>
      <input
        type="text"
        placeholder="Image URL"
        value={imageUrl}
        onChange={(e) => setImageUrl(e.target.value)}
      />
      <input
        type="text"
        placeholder="Link URL"
        value={linkUrl}
        onChange={(e) => setLinkUrl(e.target.value)}
      />
      <input
        type="text"
        placeholder="Alt Text"
        value={altText}
        onChange={(e) => setAltText(e.target.value)}
      />
      <div className="placement-selection">
        <label htmlFor="placement">Select Placement:</label>
        <select
          id="placement"
          value={placement}
          onChange={(e) => setPlacement(e.target.value)}
        >
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
          <option value="sidebar">Sidebar</option>
          <option value="in-content">In Content</option>
          
          <option value="middle">Post Middle</option> 
          <option value="post-top">Post Top</option> 
          <option value="post-bottom">Post Botton</option> 
        </select>
      </div>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <button onClick={handleCreateAd}>Create Ad</button>

      <h3>Existing Ads</h3>
      <div className="ads-list">
        {ads.length > 0 ? (
          ads.map(ad => (
            <div key={ad.id} className="ad-item">
              <div className="ad-details">
                <img
                  src={ad.imageUrl}
                  alt={ad.altText}
                  onLoad={(event) => handleImageLoad(ad, event)}
                />
                <p><strong>Link:</strong> <a href={ad.linkUrl} target="_blank" rel="noopener noreferrer">{ad.linkUrl}</a></p>
                <p><strong>Alt Text:</strong> {ad.altText}</p>
                <p><strong>Placement:</strong> {ad.placement}</p>
                {ad.imageWidth && ad.imageHeight && (
                  <p><strong>Size:</strong> {ad.imageWidth} x {ad.imageHeight} px</p>
                )}
                <button onClick={() => handleDeleteAd(ad.id)} className="delete-button">Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No ads available.</p>
        )}
      </div>
    </div>
  );
};

export default CreateAd;

